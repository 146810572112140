import React, { useRef } from "react"

// import { isEmpty } from "lodash"

import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import Form from "./elements/Form"
import LanguageSelect from "./elements/LanguageSelect"
import PhoneInput from "./elements/PhoneInput"
import Recaptcha from "./elements/Recaptcha"
import TermsConditions from "./elements/TermsConditions"
import TextInput from "./elements/TextInput"
import { registerInterestSchema } from "./schemas"
import {
  formTracking,
  generateUuid,
  getBitrixData,
  isProduction,
  postFormData,
  sendMail,
  sendToMortgageFunnel,
  useBlock,
} from "./utils"
import { CheckboxInput } from "./elements/CheckboxInput"
import useEmailContent from "@hooks/dataHooks/useEmailContent"
import { useGlobalStore } from "@stores/globalStore"
import TextAreaInput from "./elements/TextAreaInput"
const RegisterInterestForm = ({ offplan, noScrollOnSuccess }) => {
  const recaptchaRef = useRef()
  const data = useEmailContent()
  const { ipAddress } = useGlobalStore()
  const formConfig = FORM_CONFIG.registerInterest
  let emailContentTemp = data?.filter((item) => item.choose_template ===  formConfig?.emailTempUser);
  const emailContent = emailContentTemp[0]?.content?.data?.content;
  const onSubmit = async (data) => {
    const ipList = await useBlock();
    const isBlocked = ipList?.ip?.some(item => item?.add_ip_address === ipAddress);
    const isBlocked1 = ipList?.email?.some(item => item?.email === data.email);


    const token = await recaptchaRef.current?.executeAsync()
    sessionStorage.setItem("fromData", JSON.stringify(data))
    if (token) {
      const unique_id = generateUuid()
      sessionStorage.setItem("unique_id", unique_id)
      const postData = {
        first_name: data.name,
        propertyName: offplan?.title,
        offplanURL: window.location.href,
        url: window.location.href,
        ...data,
        ...formConfig,
        unique_id: unique_id,
        "g-recaptcha-response": token,
        extra: { formConfig, data },
        propertyType: offplan?.bitrix?.property_type,
        utm_campaign: offplan?.bitrix?.campaign_name,
        lead_src: offplan?.bitrix?.source,
        utm_medium: offplan?.bitrix?.project_location,
        utm_term: offplan?.bitrix?.developer_name,
        isOffPlan: true,
      }

      if (data.needFiniancialAdvice) {
        postData.needFiniancialAdvice = "Yes"
        postData.message = `This lead needs financial advice. ${
          postData.message || ""
        }`
      } else {
        postData.needFiniancialAdvice = "No"
      }

      const bitrixData = getBitrixData(postData)

      const formData = new FormData()

      formData.append("data", JSON.stringify(bitrixData))

      recaptchaRef.current?.reset()
      if(isBlocked || isBlocked1){
        return;
      }
      else {
      postFormData(formData).then(async () => {
        await sendMail(bitrixData)

        if (data.needFiniancialAdvice) {
          await sendToMortgageFunnel(postData)
        }
      })

      formTracking({
        unique_id: unique_id,
        eventTracking: formConfig.eventTracking,
        formName: formConfig.form_name,
      })
    }
    }
    
  }

  return (
    <Form
      className="register-interest-form"
      validationSchema={registerInterestSchema}
      onSubmit={onSubmit}
      successMsg={SUCCESS_MSG}
      noScrollOnSuccess={noScrollOnSuccess}
      formname="registerintrest"
    >
      <div className="form-grid">
        <TextInput name="name" placeholder="Name" label="Name" />
        <TextInput
          name="email"
          placeholder="Email"
          type="email"
          label="Email"
        />
        <PhoneInput name="telephone" label="Phone" />
        <LanguageSelect />
        <TextAreaInput
          name="message"
          placeholder=""
          label="Message"
        />
        <CheckboxInput
          name="needFiniancialAdvice"
          label="Interested in mortgage advice?"
        />
        <div className="d-none"><TextInput name="emailContent" type="hidden" value={emailContent} /></div>
      </div>
      <div className="form-bottom">
        <button className="button button-orange" type="submit">
          <span>Register your interest</span>
        </button>
        <TermsConditions />
      </div>
      <Recaptcha recaptchaRef={recaptchaRef} />
    </Form>
  )
}

export default RegisterInterestForm
