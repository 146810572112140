import * as React from "react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import { Check } from "lucide-react"
import clsx from "clsx"
import { Controller, useFormContext } from "react-hook-form"
import { useEffect } from "react"
import ResponseMessage from "./ResponseMessage"

const CheckboxInput = React.forwardRef(
  ({ boxClassName, className, name, label, value, ...props }, ref) => {
    const {
      control,
      formState: { errors, isSubmitSuccessful },
      setValue,
    } = useFormContext()

    const [checked, setChecked] = React.useState(value ? value : false)

    useEffect(() => {
      if (isSubmitSuccessful) {
        setChecked(false)
        setValue(name, false)
      }
    }, [isSubmitSuccessful, name, setValue])

    return (
      <div className={clsx("input-box-wrap input-box-message", boxClassName)}>
        <div className={clsx("input-box input-box-checkbox", className)}>
          <Controller
            control={control}
            name={name}
            defaultValue={value}
            render={({ field }) => (
              <CheckboxPrimitive.Root
                ref={ref}
                checked={checked}
                defaultChecked={value}
                className={clsx("checkbox-root")}
                onCheckedChange={(isChecked) => {
                  field.onChange(isChecked)
                  setChecked(isChecked)
                }}
                {...props}
              >
                <CheckboxPrimitive.Indicator
                  className={clsx("checkbox-indicator")}
                >
                  <Check className="check-icon" />
                </CheckboxPrimitive.Indicator>
              </CheckboxPrimitive.Root>
            )}
          />
          {label && (
            <label
              htmlFor={name}
              className="input-label"
              onClick={() => {
                setChecked(!checked)
                setValue(name, !checked)
              }}
            >
              {label}
            </label>
          )}
        </div>
        {errors && errors[name] && (
          <ResponseMessage message={errors[name].message} type="error" />
        )}
      </div>
    )
  }
)
CheckboxInput.displayName = CheckboxPrimitive.Root.displayName

export { CheckboxInput }
